.App {
  font-family: Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida,
    "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
  font-size: 22px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media only screen and (min-width: 1200px) {
  .App {
    font-size: 24px;
    flex-direction: row;
  }
}
