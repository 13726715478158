.skillsAndExperience {
  padding: 0px;
}

.skillsAndExperience ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.skillsAndExperience li {
  margin-bottom: 8px;
}

ul.skills h4 {
  display: inline;
}

ul.experience h4 {
  margin-bottom: 0px;
}

ul.experience a {
  color: #00cc99;
  text-decoration: none;
  display: block;
}

ul.experience a.closed {
  text-decoration: line-through;
  pointer-events: none;
  cursor: default;
}

ul.experience span {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .skillsAndExperience {
    padding: 64px;
  }

  .skillsAndExperience ul {
    padding-inline-start: 40px;
  }

  ul.experience a {
    display: inline;
  }

  ul.experience h4 {
    display: inline;
  }

  ul.experience span {
    display: inline;
  }
}
